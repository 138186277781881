import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as Config } from 'src/environments/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Store } from '@ngrx/store';
import * as AuthActions from '../store/auth.actions';
import { OperatorDTOExtended } from '../../operators/operators.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly http = inject(HttpClient);
  private readonly sessionStorageService = inject(SessionStorageService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly store = inject(Store);

  public login (): Observable<OperatorDTOExtended> {
    return this.http.get<OperatorDTOExtended>(`${Config.apiBaseUrl}/operator/login-new`);
  }

  public logout (): void {
    this.sessionStorageService.clear();
    this.localStorageService.clear();
    this.store.dispatch(AuthActions.logout());
  }

}
